<template>
  <div
    :class="{
      'py-40 md:py-56': inGrid <= 1,
      'md:layout-container': !block.FullWidth && inGrid <= 1
    }"
    class="max-w-full"
  >
    <div
      class="relative flex mobOnly:flex-col mobOnly:aspect-[4/5] h-auto md:h-full"
      :style="colors"
      :class="{
        'flex-col md:py-48 justify-center': inGrid > 1,
        'md:aspect-square': inGrid === 2,
        'mobOnly:justify-center': inGrid === 1 && !block.OverlayImage?.Url,
      }"
    >
      <NuxtImg
        v-if="block.BackgroundImageMobile?.Url"
        :src="block.BackgroundImageMobile?.Url"
        alt=""
        class="md:hidden absolute top-0 w-full h-full object-cover"
        preset="standard"
        sizes="100vw md:100vw"
      />
      <NuxtImg
        v-if="block.BackgroundImage?.Url"
        :src="block.BackgroundImage?.Url"
        alt=""
        class="mobOnly:hidden absolute top-0 w-full h-full object-cover"
        preset="standard"
        sizes="100vw md:100vw"
      />

      <div
        class="w-full relative flex mobOnly:flex-col"
        :class="{
          'md:flex-row-reverse': block.MainBodyPosition !== 'Right' && inGrid === 1,
          'flex-col': inGrid > 1,
          'md:layout-container md:aspect-2/1': inGrid === 1
        }"
      >
        <div
          v-if="block.OverlayImage?.Url"
          class="py-24 px-48 flex flex-col justify-center items-center relative mobOnly:pb-0 mobOnly:pt-32 mobOnly:max-h-[220px] mx-auto"
          :class="{
            'md:basis-1/2': inGrid === 1,
            'md:max-w-3/4': inGrid === 2
          }"
        >
          <NuxtImg
            v-if="block.OverlayImage.Url"
            :src="block.OverlayImage.Url"
            preset="standard"
            sizes="100vw md:534px"
            class="max-h-full"
          />
        </div>
        <div
          class="p-24 flex flex-col justify-center items-center relative"
          :class="{
            'md:basis-1/2': inGrid === 1
          }"
        >
          <div class="empty:hidden">{{ block.Tagline }}</div>
          <WysiwygWrapper
            v-if="block.MainBody"
            :html="block.MainBody"
            style-config="wysiwyg"
            :class=" {
              'has-theme-color': props.block.ThemeColor,
              'mb-32': block.PrimaryButton?.LinkUrl || block.SecondaryButton?.LinkUrl
            }"
          />

          <div class="flex gap-16 flex-wrap items-center justify-center">
            <nuxt-link
              v-if="block.PrimaryButton?.LinkUrl"
              :to="block.PrimaryButton.LinkUrl"
              class="btn btn-primary hover:brightness-90"
              :style="themePrimaryButton"
            >
              {{ block.PrimaryButton.Text }}
            </nuxt-link>

            <nuxt-link
              v-if="block.SecondaryButton?.LinkUrl"
              :to="block.SecondaryButton.LinkUrl"
              class="btn btn-basic"
              :style="themeBasicButton"
            >
              {{ block.SecondaryButton.Text }}
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ZynPromoBlockFragment } from '#gql';
import WysiwygWrapper from '~/components/WysiwygWrapper.vue';
import { type CSSProperties } from 'vue';

interface Props {
  block: ZynPromoBlockFragment,
  isMobile: boolean,
  isEditMode: boolean,
  inGrid?: number, // if part of a grid, either 2 or 3 columns on desktop
}

const props = withDefaults(defineProps<Props>(), {
  inGrid: 1,
});

const { themePrimaryButton, themeBasicButton } = useThemeColors(props.block.ThemeColor);

const colors = computed(()=> {
  const style = {} as CSSProperties;
  if (props.block.ThemeColor) {
    style.color = props.block.ThemeColor;
  }
  if (props.block.BackgroundColor) {
    style.background = props.block.BackgroundColor;
  }
  return style;
});

</script>

<style scoped lang="postcss">
</style>
